<template>
  <div>
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="addShow = true">添加负责人</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
      </el-form-item>
    </el-form>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #QRCode="{ row }">
        <el-image
          style="width: 60px; border-radius: 6px; height: 60px"
          :src="row.qr_pic"
        ></el-image>
      </template>

      <template #handle="{ row }">
        <el-button type="success" size="small" @click="openUpdate(row)"
          >修改</el-button
        >
         <el-button type="danger" size="small" @click="delRelative(row)"
          >删除</el-button
        >
      </template>
    </auto-table>

    <!-- 添加员工 -->
    <el-dialog title="添加员工" :visible.sync="addShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="二维码">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="getQRCode"
            :url="addForm.QRCode"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="负责人" required>
          <admin-select @change="changeAdmin"></admin-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addShow = false">取 消</el-button>
        <el-button type="primary" @click="createRelative">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改-->
    <el-dialog title="修改" :visible.sync="updateShow" width="30%">
      <el-form ref="ruleForm" label-width="100px">
        <el-form-item label="二维码">
          <avatar-uploader
            style="border-radius: 50%"
            @getImgUrl="(v) => (updateInfo.QRCode = v)"
            :url="updateInfo.qr_pic"
          ></avatar-uploader>
        </el-form-item>
        <el-form-item label="负责人" required>
          <admin-select @change="changeAdmin" :info="updateInfo.admin_name"></admin-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="updateShow = false">取 消</el-button>
        <el-button type="primary" @click="updateRelative">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>
import adminSelect from '../../../components/select/adminSelect.vue';
export default {
  components: { adminSelect },
  props: {
    mechanism_id: {
      default: 0,
    },
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "头像", value: "QRCode", type: "custom" },
        { name: "负责人", value: "admin_name" },
        { name: "绑定时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom" },
      ],
      Total: 0,
      addShow: false,
      addForm: {
        name: "",
        phone: "",
      },

      updateShow: false,
      updateInfo: {},

      manager: JSON.parse(localStorage.getItem("managerInfo")),

      curAdmin:{},

      userid: "",
    };
  },

  mounted() {
    
  },
  methods: {
   changeAdmin(v){
    this.curAdmin = v
   },
   
    openUpdate(row) {
      this.updateInfo = Object.assign({}, row);
      this.updateShow = true;
    },

    delRelative(row) {
      this.$confirm("确定删除社区的负责人").then(() => {
        this.$axios({
          url: "/user/adminMec/delete",
          params: {
            id: row.id,
          },
        }).then((res) => {
          this.$message("解除成功");
          this.getList(1);
        });
      });
    },
    updateRelative() {
      this.$axios({
        url: "/user/adminMec/update",
        method: "post",
        data: {
            id: this.updateInfo.id, 
          admin_id: this.curAdmin.id,
          admin_name: this.curAdmin.name,
          handle_id: this.manager.id,
          handle_name: this.manager.name,
          qr_pic: this.updateInfo.QRCode || null,
        },
      })
        .then((res) => {
          this.updateShow = false;
          this.getList(1);
          this.$message("成功");
          this.category = [];
        })
        .catch((err) => {
          this.$message.error("失败");
        });
    },
    createRelative() {
      this.$axios({
        url: "/user/adminMec/insert",
        method: "post",
        data: {
          admin_id: this.curAdmin.id,
          admin_name: this.curAdmin.name,
          handle_id: this.manager.id,
          handle_name: this.manager.name,
          qr_pic: this.addForm.QRCode || null,
            mechanism_id: this.mechanism_id,
        },
      })
        .then((res) => {
          this.addShow = false;
          this.getList(1);
          this.$message("添加成功");
          this.category = [];
        })
        .catch((err) => {
          this.$message.error("添加失败");
        });
    },
    getQRCode(v) {
      this.addForm.QRCode = v;
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/adminMec/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          mechanism_id: this.mechanism_id,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.color {
  color: rgb(238, 145, 57);
}
</style>