<template>
  <div>
    <el-descriptions title="楼栋列表" :column="1">
      <el-descriptions-item label="区域" v-if="no_Map.area">
        <el-tag
          :key="i"
          v-for="(tag, i) in no_Map.area"
          closable
          :disable-transitions="false"
          @close="handleDel(tag, 'area')"
        >
          {{ tag }}
        </el-tag></el-descriptions-item
      >
      <el-descriptions-item label="楼栋号" v-if="no_Map.floor_no">
        <el-tag
          :key="i"
          v-for="(tag, i) in no_Map.floor_no"
          closable
          :disable-transitions="false"
          @close="handleDel(tag, 'floor_no')"
        >
          {{ tag }}
        </el-tag></el-descriptions-item
      >
      <el-descriptions-item label="单元" v-if="no_Map.unit_no">
        <el-tag
          :key="i"
          v-for="(tag, i) in no_Map.unit_no"
          closable
          :disable-transitions="false"
          @close="handleDel(tag, 'unit_no')"
        >
          {{ tag }}
        </el-tag></el-descriptions-item
      >
      <el-descriptions-item label="楼层" v-if="no_Map.room_floor">
        <el-tag
          :key="i"
          v-for="(tag, i) in no_Map.room_floor"
          closable
          :disable-transitions="false"
          @close="handleDel(tag, 'room_floor')"
        >
          {{ tag }}
        </el-tag></el-descriptions-item
      >
      <el-descriptions-item label="房间号" v-if="no_Map.room">
        <el-tag
          :key="i"
          v-for="(tag, i) in no_Map.room"
          closable
          :disable-transitions="false"
          @close="handleDel(tag, 'room')"
        >
          {{ tag }}
        </el-tag></el-descriptions-item
      >
    </el-descriptions>

    <el-descriptions title="添加楼栋信息" :column="1"> </el-descriptions>

    <el-form>
      <el-form-item label="添加类型">
        <el-radio v-model="key" label="area">区域</el-radio>
        <el-radio v-model="key" label="floor_no">楼栋号</el-radio>
        <el-radio v-model="key" label="unit_no">单元</el-radio>
        <el-radio v-model="key" label="area">区域</el-radio>
         <el-radio v-model="key" label="room_floor">楼层</el-radio>
          <el-radio v-model="key" label="room">房间号</el-radio>
      </el-form-item>
      <p>楼层和房间号将组合为用户选择的房间号，如房间号1201需要添加的楼层为12，12-01添加的楼层为'12-',按实际需求是否添加后缀</p>
      <el-form-item label="楼栋数据:">
        <el-tag
          :key="tag"
          v-for="tag in dynamicTags"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          type="success"
          @click="showInput"
          >添加数据</el-button
        >
        <el-popover width="500" trigger="click" placement="top">
          <div class="">
            <p class="p">批量添加,如:1-20幢</p>
            <p class="p"> 小于10是否补0  <el-checkbox v-model="zero">补0</el-checkbox></p>
            <div class="flex">
              <p>开始</p>
              <el-input
                v-model="batch.start"
                type="number"
                placeholder="开始序号"
              ></el-input>
              <p>结束</p>
              <el-input
                v-model="batch.end"
                type="number"
                placeholder="结束序号"
              ></el-input>
              <p>后缀</p>
              <el-input v-model="batch.suffix"   placeholder="后缀"></el-input>
            </div>
            <el-button type="primary" @click="batchAddValues">确定</el-button>
          </div>

          <el-button type="text" slot="reference">批量添加</el-button>
         
        </el-popover>
          <el-button type="text" @click="dynamicTags = []">清空</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="insertDict">增加楼栋信息</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["use_id"],
  data() {
    return {
      key: "floor_no",
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      batch: {},
      no_Map: {},
      zero:true
    };
  },
  mounted() {
    this.getInfo();
  },

  methods: {
    handleDel(tag, key) {
      this.$confirm("确定删除" + tag).then(() => {
        let data = {
          use_id: this.use_id,
          type: "building",
          d_value: tag,
          d_key: key,
        };
        this.$post("user/dict/del", data).then((res) => {
          this.getInfo();
        });
      });
    },
    getInfo() {
      let data = {
        use_id: this.use_id,
        type: "building",
      };
      this.$get("user/dict/getMap", data).then((res) => {
        this.no_Map = res.data.data;
      });
    },

    insertDict() {
      let data = {
        use_id: this.use_id,
        type: "building",
        d_key: this.key,
        values: this.dynamicTags,
      };
      this.$post("user/dict/insertBatch", data).then((res) => {
        this.$message("成功");
        this.getInfo()
        this.dynamicTags = [];
      });
    },
    batchAddValues() {
      for (let index = this.batch.start; index <= this.batch.end; index++) {
        let num = index
        if(this.zero){
            num = num < 10 ? '0'+num : num
        }
        let value = num + "" + (this.batch.suffix || '');
        if (!this.dynamicTags.includes(value)) {
          this.dynamicTags.push(value);
        }
      }
    },

    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
  },
};
</script>

<style scoped>
.p{
  line-height: 2;
}
</style>