<template>
  <div class="container">
    <el-form :inline="true">
       <el-form-item>
        <el-button type="primary" @click="add">添加到店人</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #pic="{ row }">
        <div>
          <el-button type="text" @click="lookPic(row)">查看</el-button>
        </div>
      </template>
    </auto-table>


    <el-dialog
    title="提示"
    :visible.sync="picShow"
    width="30%">
    <div>
        <div v-if="cur.pic">
            <el-image 
                v-for="(p,i) in cur.pic.split(',')"
                :key="i"
                style="width: 100px; height: 100px"
                :src="p" 
                :preview-src-list="cur.pic.split(',')">
            </el-image>
        </div>
        <div v-else>
            暂无图片
        </div>
        <div>
            {{cur.remark}}
        </div>
        
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="picShow = false">关 闭</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "到店人", value: "name" },
        { name: "电话", value: "phone" },
        { name: "说明", value: "remark" },
        { name: "添加时间", value: "create_time" },
        { name: "凭证", value: "pic", type: "custom" },
        // { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 0,
      mechanism_id:'',
      picShow:false,
      cur:{}
    };
  },

  mounted() {
    this.mechanism_id = this.$route.query.mecID
    
  },

  methods: {
    lookPic(v){
        this.cur = v
        this.picShow = true
    },
    add(){
        this.$router.push({name:'addARecord',query:{mid:this.mechanism_id}})
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/userArrivalRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>