<template>
  <div class="">
    <p class="adj-wrap" v-if="mecAdjust.id">
      剩余结算调整金额(<span>
        {{ mecAdjust.adjust_amount > 0 ? "减少结算" : "增加结算" }}</span
      >)：￥<span class="adj"> {{ Math.abs(mecAdjust.adjust_amount) }}</span>
    </p>
    <p class="adj-wrap" v-else>暂无调整</p>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #adjust_amount="{ row }">
        <div>{{ Math.abs(row.adjust_amount) }}</div>
      </template>

      <template #type="{ row }">
        <el-tag v-if="row.adjust_amount > 0" type="danger">
          减少结算金额
        </el-tag>
        <el-tag v-else> 增加结算金额 </el-tag>
      </template>
    </auto-table>
  </div>
</template>


<script>
export default {
  props: ["mechanism_id"],
  data() {
    return {
      DataList: [],
      Option: [
        { name: "课程", value: "course" },
        { name: "类型", value: "type", type: "custom" },
        {
          name: "调整金额",
          value: "adjust_amount",
          type: "custom",
        },
        { name: "操作人", value: "admin_name" },
        { name: "创建时间", value: "create_time" },
        { name: "备注", value: "remark" },
      ],
      Total: 0,

      mecAdjust: {},
    };
  },
  watch: {
    mechanism_id: {
      immediate: true,
      handler(nv, ov) {
        if (nv) {
          this.getList(1);
          this.getMecAdjust();
        }
      },
    },
  },
  

  methods: {
    getMecAdjust() {
      this.$axios({
        url: "/user/userAdjust/queryListPage",
        params: {
          mechanism_id: this.mechanism_id,
        },
      }).then((res) => {
        this.mecAdjust = res.data.data[0];
      });
    },
    getList(v) {
      let page = v || 1;
      if (this.mechanism_id) {
        let params = {
          currentPage: page,
          pageSize: 10,
          mechanism_id: this.mechanism_id,
        };
        this.$axios({
          url: "/user/userAdjustRecord/queryManagerListPage",
          params,
        }).then((res) => {
          this.DataList = res.data.data.rows;
          this.Total = res.data.data.total;
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tips {
  font-size: 13px;
  color: rgb(163, 161, 161);
  line-height: 2;
}

.students {
  flex-wrap: wrap;
}
.stu-item {
  margin-left: 20px;
  margin-top: 10px;
  .stu-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .stu-name {
    font-size: 12px;
  }
}
.stu-item:hover {
  cursor: pointer;
}
.adj-wrap {
  font-size: 16px;
  padding: 10px;
}
.adj {
  font-size: 20px;
  font-weight: 600;
}
</style>